<template>
	<div>
		<CModal
			:show.sync="isShow"
			:close-on-backdrop="false"
			centered
			size="lg"
		>
			<template #header-wrapper>
				<ModalHeaderWrapper :title="title" @onClose="close" />
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetEmail
							ref="form-email"
							:rule="localRule"
							:email-list="localEmailList"
							@onGetEmail="handleGet"
						>
							<template #no-item>
								<div class="no-item d-flex text-center" data-test-id="no-item">
									<div class="d-block">
										<div
											class="font-weight-bolder color-black-45"
										>
											No email yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "email" in order to set condition.
										</div>
									</div>
								</div>
							</template>
						</FormGetEmail>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:total-select="localEmailList.length"
					:is-show-clear="true"
					:disabled-confirm="!isListChanged"
					@onCancel="handleCancel"
					@onClear="handleClear"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import FormGetEmail from '@/components/FormGetEmail.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';

export default {
	name: 'ModalEmail',
	components: {
		ModalHeaderWrapper,
		FormGetEmail,
		BaseModalFooter,
	},
	props: {
		title: {
			type: String,
			default: 'Add email',
		},
		titleConfirm: {
			type: String,
			default: null,
		},
		descriptionConfirm: {
			type: String,
			default: null,
		},
		rule: {
			type: String,
			default: null,
		},
		emailList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			isListChanged: false,
			localEmailList: [],
			localRule: '',
		};
	},
	methods: {
		async resetState() {
			this.localRule = this.rule;
			this.localEmailList = [...this.emailList];
			this.isListChanged = false;
			await this.$nextTick();
			this.$refs['form-email'].resetState();
		},
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		submit() {
			this.isShow = false;
			this.$emit('onConfirm', { rule: this.localRule, list: this.localEmailList });
		},
		handleGet({ rule, list }) {
			this.localRule = rule;
			this.localEmailList = list;
			this.isListChanged = true;
		},
		handleCancel() {
			this.close();
		},
		async handleClear() {
			this.localRule = '';
			this.localEmailList = [];
			this.isListChanged = true;
			await this.$nextTick();
			this.$refs['form-email'].resetState();
		},
		handleSubmit() {
			this.submit();
		},
	},
};
</script>

<style lang="scss" scoped>
	.no-item {
		min-height: rem(200);
		justify-content: center;
		align-items: center;
	}

	::v-deep .list-item {
		min-height: rem(200);
		padding: 0;
		margin: 0;
	}
</style>
