import { render, staticRenderFns } from "./ModalCustomerID.vue?vue&type=template&id=28ffb278&scoped=true&"
import script from "./ModalCustomerID.vue?vue&type=script&lang=js&"
export * from "./ModalCustomerID.vue?vue&type=script&lang=js&"
import style0 from "./ModalCustomerID.vue?vue&type=style&index=0&id=28ffb278&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28ffb278",
  null
  
)

export default component.exports