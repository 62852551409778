<template>
	<div>
		<CRow>
			<CCol md="12">
				<h2 class="typo-h4 position-relative">
					Customer condition
				</h2>
			</CCol>
		</CRow>
		<CRow class="mt-4">
			<CCol md="8">
				<label>Condition type</label>
			</CCol>
			<CCol md="4" class="text-right">
				<CInputRadioGroup
					:options="CONDITION_TYPE_OPTIONS"
					:custom="true"
					:inline="true"
					:checked="conditionType"
					size="sm"
					class="radio-group"
					name="customer-condition-type"
					@update:checked="handleChangeConditionType"
				/>
			</CCol>
		</CRow>

		<CRow class="mt-3">
			<CCol>
				<label class="pt-3">Customer ID</label>
				<CButton
					type="button"
					class="btn btn-add float-right"
					color="secondary"
					@click="handleAddCustomerIdClick"
				>
					{{ customerIdTitle }}
				</CButton>
			</CCol>
		</CRow>

		<CRow class="mt-3">
			<CCol>
				<label class="pt-3">Mobile number</label>
				<CButton
					type="button"
					class="btn btn-add float-right"
					color="secondary"
					@click="handleAddMobileNumberClick"
				>
					{{ phoneNumberTitle }}
				</CButton>
			</CCol>
		</CRow>

		<CRow class="mt-3">
			<CCol>
				<label class="pt-3">Email</label>
				<CButton
					type="button"
					class="btn btn-add float-right"
					color="secondary"
					@click="handleAddEmailClick"
				>
					{{ emailTitle }}
				</CButton>
			</CCol>
		</CRow>

		<CRow class="mt-4">
			<CCol md="4 pt-4">
				<label class="pt-3">Registered date</label>
			</CCol>
			<CCol md="4">
				<BaseInputDate
					v-model="registeredStartDate"
					label="From"
					placeholder="Select date"
					@input="handleRegisteredStartDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
			<CCol md="4">
				<BaseInputDate
					v-model="$v.registeredEndDate.$model"
					:disabled="!registeredStartDate"
					:is-valid="!$v.registeredEndDate.$error"
					:min-date="registeredStartDate"
					label="To"
					invalid-feedback="Date is required"
					placeholder="Select date"
					@input="handleRegisteredEndDateChange"
				>
					<template #append-content>
						<CIcon class="cil-calendar" name="cil-calendar" />
					</template>
				</BaseInputDate>
			</CCol>
		</CRow>

		<ModalCustomerID
			ref="modal-customer-id"
			:customer-id-list="customerIdList"
			:title="customerIdTitle"
			@onConfirm="handleCustomerIDListChange"
		/>
		<ModalPhoneNumber
			ref="modal-phone-number"
			:rule="phoneNumberRule"
			:phone-number-list="phoneNumberList"
			:title="phoneNumberTitle"
			@onConfirm="handlePhoneNumberListChange"
		/>
		<ModalEmail
			ref="modal-email"
			:rule="emailRule"
			:email-list="emailList"
			:title="emailTitle"
			@onConfirm="handleEmailListChange"
		/>
	</div>
</template>

<script>
import dayjs from 'dayjs';
import { requiredIf } from 'vuelidate/lib/validators';
import { validationMixin } from 'vuelidate';

import ModalCustomerID from '@/components/ModalCustomerID.vue';
import ModalPhoneNumber from '@/components/ModalPhoneNumber.vue';
import ModalEmail from '@/components/ModalEmail.vue';

import { convertDateTimeToUTC, datetimeFormat } from '../assets/js/helpers';

import {
	CONDITION_TYPE_OPTIONS,
	CONDITION_TYPES,
	CUSTOMER_CONDITION_TYPES,
} from '../enums/promotions';

export default {
	name: 'PromotionCustomerCondition',
	validations() {
		return {
			registeredEndDate: {
				required: requiredIf((vm) => vm.registeredStartDate),
			},
		};
	},
	components: {
		ModalCustomerID,
		ModalPhoneNumber,
		ModalEmail,
	},
	mixins: [validationMixin],
	props: {
		defaultData: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		const { type = CONDITION_TYPES.ALL, param = {} } = this.defaultData || {};
		let customerIdList = [];
		let phoneNumberRule = '';
		let phoneNumberList = [];
		let emailRule = '';
		let emailList = [];
		let registeredStartDate = null;
		let registeredEndDate = null;
		if (param.conditions) {
			const customerIdCondition = param.conditions.find((cond) => cond.type === CUSTOMER_CONDITION_TYPES.CUSTOMER_ID);
			const phoneNumberCondition = param.conditions.find((cond) => cond.type === CUSTOMER_CONDITION_TYPES.MOBILE_NUMBER);
			const emailCondition = param.conditions.find((cond) => cond.type === CUSTOMER_CONDITION_TYPES.EMAIL);
			const registeredCondition = param.conditions.find((cond) => cond.type === CUSTOMER_CONDITION_TYPES.REGISTER_DATE);
			customerIdList = customerIdCondition?.param?.value ?? [];
			phoneNumberRule = phoneNumberCondition?.param?.rule ?? '';
			phoneNumberList = phoneNumberCondition?.param?.value ?? [];
			emailRule = emailCondition?.param?.rule ?? '';
			emailList = emailCondition?.param?.value ?? [];
			registeredStartDate = registeredCondition?.param?.from ?? null;
			registeredEndDate = registeredCondition?.param?.to ?? null;
			registeredStartDate = registeredStartDate ? new Date(datetimeFormat(registeredStartDate, 'YYYY-MM-DD')) : null; // convert to be type of Date
			registeredEndDate = registeredEndDate ? new Date(datetimeFormat(registeredEndDate, 'YYYY-MM-DD')) : null; // convert to be type of Date
		}

		return {
			CONDITION_TYPES,
			CONDITION_TYPE_OPTIONS,
			conditionType: type,
			registeredStartDate,
			registeredEndDate,
			customerIdList,
			phoneNumberRule,
			phoneNumberList,
			emailRule,
			emailList,
		};
	},
	computed: {
		customerIdTitle() {
			return `${this.customerIdList && this.customerIdList.length ? 'Edit' : 'Add'} customer ID`;
		},
		phoneNumberTitle() {
			return `${this.phoneNumberList && this.phoneNumberList.length ? 'Edit' : 'Add'} phone number`;
		},
		emailTitle() {
			return `${this.emailList && this.emailList.length ? 'Edit' : 'Add'} email`;
		},
	},
	methods: {
		handleRegisteredStartDateChange(value) {
			if (!value) {
				this.registeredEndDate = null;
			}

			if (!this.registeredEndDate || dayjs(value).isAfter(dayjs(this.registeredEndDate))) {
				this.registeredEndDate = value;
			}

			this.handleUpdateValue();
		},
		handleRegisteredEndDateChange() {
			this.handleUpdateValue();
		},
		handleChangeConditionType(value) {
			this.conditionType = value;
			this.handleUpdateValue();
		},
		handleAddCustomerIdClick() {
			this.$refs['modal-customer-id'].open();
		},
		handleAddMobileNumberClick() {
			this.$refs['modal-phone-number'].open();
		},
		handleAddEmailClick() {
			this.$refs['modal-email'].open();
		},
		handleCustomerIDListChange(list) {
			this.customerIdList = list;
			this.handleUpdateValue();
		},
		handlePhoneNumberListChange({ rule, list }) {
			if (list && Array.isArray(list) && list.length > 0) {
				this.phoneNumberRule = rule;
				this.phoneNumberList = list;
			} else {
				this.phoneNumberRule = null;
				this.phoneNumberList = [];
			}
			this.handleUpdateValue();
		},
		handleEmailListChange({ rule, list }) {
			if (list && Array.isArray(list) && list.length > 0) {
				this.emailRule = rule;
				this.emailList = list;
			} else {
				this.emailRule = null;
				this.emailList = [];
			}
			this.handleUpdateValue();
		},
		handleUpdateValue() {
			this.$v.$touch();

			const isValid = !this.$v.$invalid;

			const conditions = [];
			// Customer ID
			if (this.customerIdList && this.customerIdList.length > 0) {
				conditions.push({
					type: CUSTOMER_CONDITION_TYPES.CUSTOMER_ID,
					param: {
						value: [...this.customerIdList],
					},
				});
			}

			// Mobile number, Phone number
			if (this.phoneNumberRule && this.phoneNumberList && this.phoneNumberList.length > 0) {
				conditions.push({
					type: CUSTOMER_CONDITION_TYPES.MOBILE_NUMBER,
					param: {
						rule: this.phoneNumberRule,
						value: [...this.phoneNumberList],
					},
				});
			}

			// Email
			if (this.emailRule && this.emailList && this.emailList.length > 0) {
				conditions.push({
					type: CUSTOMER_CONDITION_TYPES.EMAIL,
					param: {
						rule: this.emailRule,
						value: [...this.emailList],
					},
				});
			}

			// Registered date
			if (this.registeredStartDate && this.registeredEndDate) {
				conditions.push({
					type: CUSTOMER_CONDITION_TYPES.REGISTER_DATE,
					param: {
						from: convertDateTimeToUTC(this.registeredStartDate),
						to: convertDateTimeToUTC(this.registeredEndDate, '23:59:59'),
					},
				});
			}

			this.$emit(
				'onUpdate',
				{
					valid: isValid,
					data: conditions.length > 0
						? {
							type: this.conditionType,
							param: {
								conditions,
							},
						}
						: null,
				},
			);
		},
	},
};
</script>

<style lang="scss" scoped>
	.condition-value {
		span {
			line-height: rem(34);
		}

		.empty-value {
			height: rem(34);
			padding: 0 rem(12);
			color: $color-black-25;
			background-color: $color-gray-200;
			border-radius: rem(4);
		}

		.is-invalid {
			border: 1px solid $color-alert;
			border-radius: rem(4);
		}
	}

	.btn-add {
		min-width: rem(180);
	}
</style>