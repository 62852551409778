<template>
	<div>
		<CModal :show.sync="isShow" :close-on-backdrop="false" centered>
			<template #header-wrapper>
				<ModalHeaderWrapper :title="title" @onClose="close" />
			</template>
			<template #default>
				<CRow>
					<CCol>
						<FormGetCustomerId
							ref="form-customer-id"
							:customer-id-list="localCustomerIdList"
							@onGetCustomerId="handleGet"
						>
							<template #no-item>
								<div class="no-item d-flex text-center" data-test-id="no-item">
									<div class="d-block">
										<div
											class="font-weight-bolder color-black-45"
										>
											No customer ID yet
										</div>
										<div class="typo-body-2 color-black-45">
											Add "customer ID" in order to set condition.
										</div>
									</div>
								</div>
							</template>
						</FormGetCustomerId>
					</CCol>
				</CRow>
			</template>
			<template #footer>
				<BaseModalFooter
					:total-select="localCustomerIdList.length"
					:is-show-clear="true"
					:disabled-confirm="!isListChanged"
					@onCancel="handleCancel"
					@onClear="handleClear"
					@onConfirm="handleSubmit"
				/>
			</template>
		</CModal>
	</div>
</template>

<script>
import ModalHeaderWrapper from '@/components/ModalHeaderWrapper.vue';
import FormGetCustomerId from '@/components/FormGetCustomerId.vue';
import BaseModalFooter from '@/components/BaseModalFooter.vue';

export default {
	name: 'ModalCustomerID',
	components: {
		ModalHeaderWrapper,
		FormGetCustomerId,
		BaseModalFooter,
	},
	props: {
		title: {
			type: String,
			default: 'Add Customer ID',
		},
		titleConfirm: {
			type: String,
			default: null,
		},
		descriptionConfirm: {
			type: String,
			default: null,
		},
		customerIdList: {
			type: Array,
			default: () => [],
		},
	},
	data() {
		return {
			isShow: false,
			isListChanged: false,
			localCustomerIdList: [],
		};
	},
	methods: {
		resetState() {
			this.$refs['form-customer-id'].resetState();
			this.localCustomerIdList = [...this.customerIdList];
			this.isListChanged = false;
		},
		open() {
			this.isShow = true;
			this.resetState();
		},
		close() {
			this.isShow = false;
		},
		submit() {
			this.isShow = false;
			this.$emit('onConfirm', this.localCustomerIdList);
		},
		handleGet(list) {
			this.localCustomerIdList = list;
			this.isListChanged = true;
		},
		handleCancel() {
			this.close();
		},
		handleClear() {
			this.localCustomerIdList = [];
			this.isListChanged = true;
		},
		handleSubmit() {
			this.submit();
		},
	},
};
</script>

<style lang="scss" scoped>
	.no-item {
		min-height: rem(200);
		justify-content: center;
		align-items: center;
	}

	::v-deep .list-item {
		min-height: rem(200);
		padding: 0;
		margin: 0;
	}
</style>
